<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../assets/cdp/banner-cdp.png" alt="" />
      <div class="banner-title">
        <!-- {{t("product.cdp.banner_title")}} -->
        <h2>{{t("product.cdp.banner_title")}}</h2>
        <h4>{{t("product.cdp.banner_sub_title")}}</h4>
      </div>
      <div class="banner-message">
        <div class="banner-icon">
          <img src="../../assets/cdp/cdp.png" alt="">
        </div>
        <div class="banner-text">
          <div>{{ t("product.cdp.banner_description") }}</div>
          <a class="banner-btn" href="#" target="_blank">{{
            t("home.banner_btn")
          }}</a>
        </div>
      </div>
    </div>
    <!-- module -->
    <div class="info-box">
      <h1 class="info-title">{{ t("product.cdp.module") }}</h1>
      <div class="info-card">
        <div class="card">
          <img class="card-icon" src="../../assets/cdp/intelligent-marketing.png" alt="" />
          <div class="card-title">{{ t("product.cdp.module_intelligent") }}</div>
          <div class="card-text">{{ t("product.cdp.module_intelligent_dec") }}</div>
        </div>
        <div class="card">
          <img class="card-icon" src="../../assets/cdp/insight-bi.png" alt="" />
          <div class="card-title">{{ t("product.cdp.module_insight") }}</div>
          <div class="card-text">{{ t("product.cdp.module_insight_dec") }}</div>
        </div>
        <div class="card">
          <img class="card-icon" src="../../assets/cdp/lifecycle-management.png" alt="" />
          <div class="card-title">{{ t("product.cdp.module_lifecycle") }}</div>
          <div class="card-text">{{ t("product.cdp.module_lifecycle_dec") }}</div>
        </div>
        <div class="card">
          <img class="card-icon" src="../../assets/cdp/loyalty-repurchase.png" alt="" />
          <div class="card-title">{{ t("product.cdp.module_loyalty") }}</div>
          <div class="card-text">{{ t("product.cdp.module_loyalty_dec") }}</div>
        </div>
        <div class="card">
          <img class="card-icon" src="../../assets/cdp/custoomer-service.png" alt="" />
          <div class="card-title">{{ t("product.cdp.module_service") }}</div>
          <div class="card-text">{{ t("product.cdp.module_service_dec") }}</div>
        </div>
      </div>
    </div>
    <div class="info-box">
      <h1 class="info-title">{{ t("product.cdp.data_pipeline") }}</h1>
      <div class="info-card info-card-img">
        <img class="img" src="../../assets/cdp/pipeline.png" alt="" />
      </div>
    </div>
    <div class="no-bg">
      <div class="info-box">
        <h1 class="info-title">{{t("product.cdp.features")}}</h1>
        <div class="info-card">
          <div class="card-img">
            <div class="img-box">
              <img src="../../assets/cdp/profile.png" alt="" />
            </div>
            <div class="sub-title">{{ t("product.cdp.customer_profile") }}</div>
            <!-- <div class="sub-dec">{{ t("product.cdp.customer_profile_description") }}</div> -->
          </div>
          <div class="card-img">
            <div class="img-box">
              <img src="../../assets/cdp/audiences.png" alt="" />
            </div>
            <div class="sub-title">{{ t("product.cdp.custom_audiences") }}</div>
            <!-- <div class="sub-dec">{{ t("product.cdp.custom_audiences_description") }}</div> -->
          </div>
          <div class="card-img">
            <div class="img-box">
              <img src="../../assets/cdp/labelling.png" alt="" />
            </div>
            <div class="sub-title">{{ t("product.cdp.custom_labelling") }}</div>
            <!-- <div class="sub-dec">{{ t("product.cdp.custom_labelling_description") }}</div> -->
          </div>
          <div class="card-img">
            <div class="img-box">
              <img src="../../assets/cdp/one-id.png" alt="" />
            </div>
            <div class="sub-title">{{ t("product.cdp.one_id") }}</div>
            <!-- <div class="sub-dec">{{ t("product.cdp.one_id_description") }}</div> -->
          </div>
          <div class="card-img">
            <div class="img-box">
              <img src="../../assets/cdp/integration.png" alt="" />
            </div>
            <div class="sub-title">{{ t("product.cdp.data_integration") }}</div>
            <!-- <div class="sub-dec">{{ t("product.cdp.data_integration_description") }}</div> -->
          </div>
          <div class="card-img">
            <div class="img-box">
              <img src="../../assets/cdp/warehouse.png" alt="" />
            </div>
            <div class="sub-title">{{ t("product.cdp.data_warehouse") }}</div>
            <!-- <div class="sub-dec">{{ t("product.cdp.data_warehouse_description") }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="world-spire">
      <div class="persons">
        <img src="../../assets/cdp/ads.png" alt="">
      </div>
      <div class="info">
        <h1>{{t("product.cdp.worldspire")}}</h1>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 0}" @click="handleClick(0)">{{t("product.cdp.ws_sub_title")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 0}">{{t("product.cdp.ws_sub_message")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 1}" @click="handleClick(1)">{{t("product.cdp.data_sub_title")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 1}">{{t("product.cdp.ws_sub_message")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 2}" @click="handleClick(2)">{{t("product.cdp.business_sub_title")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 2}">{{t("product.cdp.ws_sub_message")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 3}" @click="handleClick(3)">{{t("product.cdp.security_sub_title")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 3}">{{t("product.cdp.ws_sub_message")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 4}" @click="handleClick(4)">{{t("product.cdp.team_sub_title")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 4}">{{t("product.cdp.ws_sub_message")}}</div>
        </div>
      </div>
    </div> -->
    <Explain/>
    <HelpForm/>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import Explain from '../../components/Explain/index.vue';
import HelpForm from '../../components/HelpForm/index.vue';
export default {
    name: 'CDP',
    components: {Explain,HelpForm},
    // data() {
    //   return {
    //     isActive: null
    //   }
    // },
    setup() {
        const { t } = useI18n();
        return {
          t,
        };
    },
    // methods: {
    //   handleClick(e) {
    //     this.isActive = this.isActive === e ? null : e
    //   }
    // },
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 792px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #ffffff;
      font-weight: bold;
      font-size: 60px;
      position: absolute;
      top: 50%;
      left: 22%;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
    }
    .banner-message {
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translateX(-50%);
      width: 1140px;
      padding: 40px;
      margin: 0 auto;
      text-align: left;
      font-size: 16px;
      line-height: 26px;
      background: #ffffff;
      color: #333333;
      box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      display: flex;
      .banner-icon {
        margin-right: 35px;
      }
      .banner-text {
        position: relative;
      }
      .banner-btn {
        display: block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        background: #ff9333;
        border-radius: 24px;
        margin-top: 15px;
        float: right;
      }
    }
  }
  .info-box {
    max-width: 1140px;
    margin: 80px auto;
    text-align: center;
    padding: 20px 0;
    .info-title::after {
      content: "";
      display: block;
      width: 120px;
      text-align: center;
      margin: 20px auto 40px;
      background: #ff9333;
      border: 3px solid #ff9333;
      border-radius: 3px;
    }
    .info-card {
      display: flex;
      flex-wrap: wrap;
      &.info-card-img {
        display: block;
      }
      .card {
        flex: 1;
        width: 20%;
        background: #f4f4f4;
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
        .card-icon {
          width: 100%;
          margin-bottom: 16px;
        }
        .card-title {
          color: #333333;
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 16px;
          padding: 0 30px;
          line-height: 20px;
        }
        .card-text {
          padding: 0 20px 20px;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 24px;
        }
      }
      .img {
        width: 100%;
      }
    }
  }
  
  .no-bg {
    background: #f1f1f1;
      .card-img {
        width: 350px;
        padding: 30px 20px;
        background: #ffffff;
        margin: 15px;
        text-align: left;
        .img-box {
          img {
            width: 100%;
          }
        }
        .sub-title {
          font-size: 18px;
          color: #333333;
          font-weight: 600;
          line-height: 40px;
          text-align: center;
        }
        .sub-text {
          font-size: 12px;
          font-weight: 400;
        }
      }
  }

  .world-spire {
    max-width: 1300px;
    margin: 80px auto;
    display: flex;
    align-items: center;
    user-select: none;
    .persons {
      flex: 1;
      margin-right: 20px;
      img {
        width: 90%;
      }
    }
    .info {
      flex: 1;
      h1 {
        margin: 0;
        line-height: 100px;
        border-bottom: 1px solid #ccc;
      }
      .line-box {
        border-bottom: 1px solid #ccc;
        .line-title {
          line-height: 60px;
          font-size: 20px;
          font-weight: 600;
          color: #333333;
          cursor: pointer;
          position: relative;
          img {
            width: 12px;
            position: absolute;
            top: 48%;
            right: 10px;
            transition: transform 0.5s ease-in-out;
          }
          &.active {
            color: #ff9333;
            img {
              transform:rotate(180deg);
              transition: transform 0.5s ease-in-out;
            }
          }
        }
        .line-message {
          // display: none;
          height: 0;
          overflow: hidden;
          // margin-bottom: 20px;
          transition: height 0.5s ease-in-out;
          font-size: 12px;
          &.active {
            // display: block;
            font-size: 12px;
            height: 80px;
            transition: height 0.5s ease-in-out;
          }
        }
      }
    }
  }
}
</style>
